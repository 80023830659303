import React from 'react';

import Layout from 'src/components/layout/layout';
import Member from 'src/components/member/member';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const MemberPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Member" />
      <PrivateRoute>
        <Member member={location.state?.t} />
      </PrivateRoute>
    </Layout>
  );
};
export default MemberPage;
